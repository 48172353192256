import React from "react"
import Title from "../Title"
import styles from "../../css/contact.module.css"
const Contact = () => {
    return (
        <section className={styles.contact}>
        <Title title="a career" subtitle="with us" />
        <div className={styles.center}>
        <form action="https://formspree.io/f/xzbkkona" method="POST" className={styles.form}>
        <div>
        <label htmlFor="name">name</label>
        <input
    type="text"
    name="name"
    id="name"
    className={styles.formControl}
    placeholder="Full Name"
        />
        </div>
        <div>
        <label htmlFor="email">email</label>
        <input
    type="email"
    name="email"
    id="email"
    className={styles.formControl}
    placeholder="email@email.com"
        />
        </div>
        <div>
        <label htmlFor="message">message</label>
        <textarea
    name="message"
    id="message"
    rows="10"
    className={styles.formControl}
    placeholder="Tell us in less than 100 words how you solved a complex problem. It should be relevant to your area of interest. If we find it facinating we'll ask you for your resume."
        />
        </div>
        <div>
        <input
    type="submit"
    value="submit here"
    className={styles.submit}
    />
    </div>
    </form>
    </div>
    </section>
)
}

export default Contact
